@import "../../style/index";

@mixin close-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
  background-size: contain;
  height: 36px;
  background: url("../../assets/game/modal/close-bg.png") no-repeat;
  background-size: contain;
}

$bg-color: #921D23;

.modal {
  &-start {
    @include font-style();
    color: $text-color;
    background: linear-gradient(180deg, #BA0E1C 0%, #A51621 46.51%, #BA0F1C 99.22%);
    border: 1px solid #77141D;
    border-radius: 5px;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-transform: uppercase;
      margin-bottom: 40px;

      #modal-modal-title {
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 5px;
      }

      #modal-modal-description {
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
      }
    }

    button {
      margin-bottom: 30px;
      width: 90%;
    }

    img {
      margin-bottom: 40px;
    }
  }

  &-win {
    @include font-style();
    color: $text-color;
    background: url("../../assets/game/modal/win/bg.png") center;
    background-size: cover;
    border: 1px solid #77141D;
    border-radius: 5px;
    padding: 20px 20px 0 20px;

    .MuiFormControl-root {
      width: 100%;
    }

    .modal-body {
      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-transform: uppercase;
        margin-bottom: 40px;
        position: relative;
        z-index: 1;

        #modal-modal-title {
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
          text-transform: uppercase;
        }

        #modal-modal-description {
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          margin-top: 17px;
          text-transform: none;
        }
      }

      .code {
        background: #FFFFFF;
        border: 2px solid #931C23;
        box-shadow: 0px 4.55039px 4.55039px rgb(0 0 0 / 25%);
        border-radius: 8px;
        position: relative;

        input {
          height: 39px;
          margin-left: 20px;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
        }
      }

      .footer {
        margin-top: 20px;
        margin-bottom: 20px;

        button{
          margin: 0;
          padding: 0;
        }
      }

      .copy {
        position: absolute;
        width: 81.47px;
        height: 52px;
        right: 0;
        top: 0;

        background: #C0151E;
        border-radius: 0px 6px 6px 0px;
      }
      .copied {
        background: #931A21;
      }

      .sponsor {
        position: relative;
        z-index: 1;
        top: 0;
        width: 80%;
      }
    }
    .close-icon {
      @include close-icon();
    }
  }

  &-email {
    @include font-style();

    color: #000000;
    background: #ffffff;
    border: 1px solid #77141D;
    border-radius: 5px;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-transform: uppercase;
      margin-bottom: 40px;
      position: relative;
      z-index: 1;
      padding: 20px 24px 0 24px;
      font-family: 'Golos';

      #modal-modal-title {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        text-transform: uppercase;
      }

      #modal-modal-description {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin-top: 17px;
        text-transform: none;
      }

      .email {
        width: 100%;
        height: 54.88px;
        top: 15px;
        background: #FFFFFF;
        box-shadow: 0px 4.55039px 4.55039px rgb(0 0 0 / 25%);
        border-radius: 8px;
        position: relative;
        margin-top: 36px;
      }
    }

    .send {
      background: linear-gradient(180deg, #931C23 0%, #870008 100%);
      border-radius: 0px 0px 16px 16px;
      padding: 40px 24px 10px 24px;
      margin-top: -50px;
    }

    .close-icon {
      @include close-icon();
      color: #ffffff;
      z-index: 2;
    }
  }

  &-end {
    @include font-style();
    color: $text-color;
    background: $bg-color;
    border-radius: 5px;

    .modal-body {
      margin-bottom: -20px;
      padding: 20px 20px 0 20px;
      //background: url("../../assets/game/modal/send/sponsor.png") center;
      //background-size: cover;
      //aspect-ratio: 1/1.46;

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-transform: uppercase;
        margin-bottom: 20px;
        position: relative;
        z-index: 1;

        #modal-modal-title {
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
          text-transform: uppercase;
        }

        #modal-modal-description {
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          margin-top: 17px;
          text-transform: none;
        }
      }

      button {
        position: absolute;
        bottom: 40px;
        left: 50%;
        width: 80%;
        transform: translate(-50%, 0);
      }

      .background {
        position: relative;
        top: -80px;
        width: calc(100% + 80px);
        transform: translate(-40px, 0);
      }
    }

    .close-icon {
      @include close-icon();
    }
  }

  &-timer {
    @include font-style();
    color: $text-color;
    background: $bg-color;
    border-radius: 5px;

    .modal-body {
      padding: 20px 40px 0 40px;

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-transform: uppercase;
        position: relative;
        z-index: 1;

        #modal-modal-title {
          font-weight: 600;
          font-size: 32px;
          line-height: 38px;
          text-transform: uppercase;
        }

        #modal-modal-description {
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          margin-top: 17px;
          text-transform: none;
        }
      }

      button {
        width: 80%;
      }
    }
    .close-icon {
      @include close-icon();
    }
  }
}

