$base-color: #911D23;
$text-color: #ffffff;
$font-family: 'Golos';

@mixin font-style {
  font-family: $font-family;
  font-style: normal;
  font-weight: 400;
  color: $text-color;
  text-align: center;
}
