@import "../../../style/index";

$filter-shadow: drop-shadow(4px 6px 5px rgba(0, 0, 0, 0.1));

.banner {
  position: relative;

  .stripesbg{

  }
  .illustration{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -30%);
  }

  .logo{
    width: 150px;
    position: absolute;
    top: 6%;
    left: 50%;
    transform: translate(-50%, 0);
  }
}