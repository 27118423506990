@import "../../../style/index";

.main {
  @include font-style();
  margin: -15% auto 12px;
  position: relative;
  width: 80%;
  color: #ffffff;

  .description {
    margin: 0 auto 19px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }

  .campaign {
    .validation {
      font-size: 18px;
      line-height: 22px;
    }

    .list {
      padding-left: 20px;
      text-align: left;
    }
  }
}
