@import "../../style/index";

iframe {
  height: 800px;
  border: 0px solid;

  width: 100%;
}

.logo{
  width: 125px;
}
