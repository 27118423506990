@import "../style/index";

.bg{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
  transition: background-color 500ms ease-out;
  background-color: $base-color;
}